
  @import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&family=Work+Sans&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Work Sans', sans-serif;
}
