body {
    background-color: black !important;
    color: #fff !important;
}
.w100 {
    width: 100%;
}
.web-features{
    width: 50% !important;
}
.profileHeader {
    background-color: black;
    text-align: center;
    color: white;
    border-radius: 4px;
    padding: 5px;
    font-size: 24px;
    margin-bottom: 3px;
}
p {
    font-size: 18px !important;
}

nav {
    background: #181717 !important;
}

.privacy-terms {
    background-image: url('./images/privacy.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dropdown-toggle {
    font-weight: 500;
    color: #fff !important;
    padding-inline: 15px !important;
    margin-right: 1rem !important;
    border: 1px solid #fff !important;
}

.tools1 {
    display: none !important;
}

.bg-grey {
    background-color: #e1e1e1 !important;
}

.tools-img {
    width: 30px;
}

.tools {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.tools a span {
    padding-right: 5px;
}

.tools a {
    background: #0a0f20;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-weight: 600;
    width: 200px;
    text-align: center;
    padding-block: 20px;
    padding-inline: 5px;
    font-size: 14px;
}

.tools a:hover {
    background: linear-gradient(141deg, #9c26d7, #1eb1db);
}

.tools1 {
    display: flex;
    gap: 1rem;
}

.tools1 a span {
    padding-right: 5px;
}

.tools1 a {
    background: #0a0f20;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-weight: 600;
    width: 200px;
    text-align: center;
    padding-block: 20px;
    padding-inline: 5px;
    font-size: 14px;
}

.tools1 a:hover {
    background: linear-gradient(141deg, #9c26d7, #1eb1db);
}


.mid-btn {
    background: #0d6efd !important;
    color: #fff !important;
    font-weight:  bold !important;
    padding: 0.5rem;
    border: none;
    border-radius: 10px;
}



.middle-section-detail-1 h2 {
    font-size: 30px;
    font-weight: 600;
}

.footer-link2 a {
    padding-right: 0.5rem;
}

.footer-row {
    padding-block: 1rem;
}

.footer-ipad {
    background: #181717 !important;
}

.footer-container {
    background: #181717 !important;
    margin-top: 3rem;
}

.footer-link {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}


.footeer-img img {
    width: 100% !important;
}

@media screen and (max-width:576px) {
    .web-features{
        width: 100% !important;
    }
    .captcha {
        transform: scale(0.70) !important;
        transform-origin: 0 0 !important;
    }

    .contact-form {
        margin-inline: 0rem !important;
        padding: 0 !important;
    }

    .dropdown-toggle {
        font-weight: 500;
        color: #fff !important;
        border: 1px solid #fff !important;
        padding-inline: 15px !important;
        margin-right: 1rem !important;
        width: 87px !important;
        margin-bottom: 0.5rem;
    }

    .tools a {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .bg-reverse {
        flex-direction: column-reverse !important;
    }

    .tools {
        display: none;
    }

    .tools1 {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
    }

    .footer-link {
        flex: unset;
        text-align: center !important;
        padding-inline: 1rem !important;
    }

    .footer-link2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.3rem;
    }

    .footeer-img a img {
        width: 60% !important;
    }

    .footeer-img {
        text-align: center;
    }

    .web-content {
        margin-inline: 1.3rem !important;
    }
}

@media screen and (max-width:992px) {
    .bg-reverse {
        flex-direction: column-reverse !important;
    }

}

@media only screen and (min-width: 576px) and (max-width: 768px) {

    .tools a {
        font-size: 11px !important;
    }

}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {

    /*only for ipad case  */
   
    .footer-ipad {
        position: fixed !important;
        bottom: 0 !important;
        width: 100% !important;
    }

    .dropdown-menu[data-bs-popper] {
        top: 100%;
        left: -108px !important;
        margin-top: 1rem !important;
    }

    .tools a {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .tools {
        margin-bottom: 3.5rem;
    }
}

@media screen and (min-width:992px) {
    .dropdown-menu[data-bs-popper] {
        top: 100%;
        left: -108px !important;
        margin-top: 1rem !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

    .tools {
        margin-bottom: 2rem !important;
    }

    .tools a {
        font-size: 11px !important;
    }

}


/*for scroll bar*/

::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #3965dd;
    border-radius: 10px;
  }

  /* for error page */

  .page_404{ 
    padding:40px 0;
    color: #fff;
    font-family: 'Arvo', serif;
  }

  .page_404  img
  {
     width:100%;
  }

  .four_zero_four_bg{

   background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
      height: 400px;
      background-position: center;
   }


   .four_zero_four_bg h1{
        font-size:80px;
        color: #0a0f20;
   }

    .four_zero_four_bg h3{
        font-size:80px;
    }

    .link_404{
      padding: 10px 20px;
      background: #39ac31;
      margin: 20px 0;
      display: inline-block;
    }

    .contant_box_404{ 
            margin-top:-50px;
            color: #0a0f20;
    }